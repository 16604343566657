import React from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './courseOne.css'
import { VscActivateBreakpoints } from "react-icons/vsc";
import LastFooter from '../../home/footer/LastFooter';
import LoadingSpinner from '../../LodingSpinner/LodingSpinner';

function CourseSix() {
    const [click, setClick] = useState('graduate')

    const graduate = () => {
        setClick('graduate')
    }

    const postGraduate = () => {
        setClick('postGraduate')
    }

    const Diploma = () => {
        setClick('Diploma')
    }

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate a loading delay
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000); // Adjust the timeout as needed

        // Cleanup the timer
        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return <LoadingSpinner />;
    }



    return (
        <>
            <div className='first-box'>
                <div className="first-box-title">
                    <div>
                        <h1 className='text-white'>Cardiac Care Technology</h1>
                        <div className='d-flex justify-content-center text-white'>
                            <Link to='/' className='me-2' style={{ textDecoration: "none", color: "white" }} > Home </Link>// Cardiac Care Technology
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ background: "#f3f8f9", padding: "20px", paddingTop: "40px" }}>
                <div className="container">
                    <div>
                        <button className='me-1 courseBtn'
                            style={click === 'graduate' ? { background: "#273089", color: "white", fontWeight: "bold", border: "none", padding: "10px", width: "90px" } : { fontWeight: "bold", border: "none", padding: "10px", width: "90px" }}
                            onClick={graduate}
                        >
                            Graduate
                        </button>
                        <button
                            className='me-1 courseBtn'
                            style={click === 'postGraduate' ? { background: "#273089", color: "white", fontWeight: "bold", border: "none", padding: "10px", width: "130px" } : { fontWeight: "bold", border: "none", padding: "10px", width: "130px", }}
                            onClick={postGraduate}
                        >
                            Post Graduate
                        </button>
                        <button
                            className='courseBtn'
                            style={click === 'Diploma' ? { background: "#273089", color: "white", fontWeight: "bold", border: "none", padding: "10px", width: "90px" } : { fontWeight: "bold", border: "none", padding: "10px", width: "90px" }}
                            onClick={Diploma}
                        >
                            Diploma
                        </button>

                    </div>
                    <div className="row mt-1 row-gap-3">
                        {click === 'graduate' && (
                            <div className="col-md-8 bg-white p-4">
                                <h4>B.Voc in Cardiac Care Technology</h4>
                                <p style={{ color: "#626262" }}>
                                    B.Voc in Cardiac Care Technology is a 3-year full-time undergraduate course divided into 6 semesters. Cardiac Technology is a medical allied course. Cardiac technologists help physicians with invasive cardiovascular testing, which involves inserting a tube into one of the heart's blood vessels.
                                    <br /><br />
                                    B.Voc in Cardiac Care Technology has been one of the most popular alternatives for candidates among the various allied healthcare degrees offered in India. The B.Voc in Cardiac Care Technology programme is aimed at preparing students to provide therapy and pre/post-surgery care to patients with cardiopulmonary illnesses.
                                    <br /> <br />
                                    Cardiac Care Technology technicians can monitor heart rate and assess how well a heart is working. They evaluate how effectively a patient's heart is working. Cardiac care technicians are medical professionals who perform a variety of tests to help a doctor make a diagnosis.
                                </p>
                                <h3>Jop Scope</h3>
                                <h4>for B.Voc Cardiac Care Technology</h4>
                                <ul>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Cardiovascular Technologist</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Cardiac Care Technologist</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Medical Sonographer</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Lecturer</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />TMT Technician</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Echo Technician</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Emergency Medical Servicen</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />ECG Technician</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Biomedical Technicians</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />ICU, CCU Services</li>
                                </ul>
                            </div>
                        )}

                        {click === 'postGraduate' && (
                            <div className="col-md-8 bg-white p-4">
                                <h4>Post Graduate in Cardiac Care Technology</h4>
                                <p style={{ color: "#626262" }}>
                                    Post Graduate in Cardiac Care Technology is a 3-year full-time undergraduate course divided into 6 semesters. Cardiac Technology is a medical allied course. Cardiac technologists help physicians with invasive cardiovascular testing, which involves inserting a tube into one of the heart's blood vessels.
                                    <br /><br />
                                    Post Graduate in Cardiac Care Technology has been one of the most popular alternatives for candidates among the various allied healthcare degrees offered in India. The B.Voc in Cardiac Care Technology programme is aimed at preparing students to provide therapy and pre/post-surgery care to patients with cardiopulmonary illnesses.
                                    <br /> <br />
                                    Cardiac Care Technology technicians can monitor heart rate and assess how well a heart is working. They evaluate how effectively a patient's heart is working. Cardiac care technicians are medical professionals who perform a variety of tests to help a doctor make a diagnosis.
                                </p>
                                <h3>Jop Scope</h3>
                                <h4>for Post Graduate Cardiac Care Technology</h4>
                                <ul>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Cardiovascular Technologist</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Cardiac Care Technologist</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Medical Sonographer</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Lecturer</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />TMT Technician</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Echo Technician</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Emergency Medical Servicen</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />ECG Technician</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Biomedical Technicians</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />ICU, CCU Services</li>
                                </ul>
                            </div>

                        )}

                        {
                            click === 'Diploma' && (
                                <div className="col-md-8 bg-white p-4">
                                    <h4>Diploma in Cardiac Care Technology</h4>
                                    <p style={{ color: "#626262" }}>
                                        Diploma in Cardiac Care Technology is a 3-year full-time undergraduate course divided into 6 semesters. Cardiac Technology is a medical allied course. Cardiac technologists help physicians with invasive cardiovascular testing, which involves inserting a tube into one of the heart's blood vessels.
                                        <br /><br />
                                        Diploma in Cardiac Care Technology has been one of the most popular alternatives for candidates among the various allied healthcare degrees offered in India. The B.Voc in Cardiac Care Technology programme is aimed at preparing students to provide therapy and pre/post-surgery care to patients with cardiopulmonary illnesses.
                                        <br /> <br />
                                        Cardiac Care Technology technicians can monitor heart rate and assess how well a heart is working. They evaluate how effectively a patient's heart is working. Cardiac care technicians are medical professionals who perform a variety of tests to help a doctor make a diagnosis.

                                    </p>
                                    <h3>Jop Scope</h3>
                                    <h4>for Diploma Dialysis Technology</h4>
                                    <ul>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Cardiovascular Technologist</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Cardiac Care Technologist</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Medical Sonographer</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Lecturer</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />TMT Technician</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Echo Technician</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Emergency Medical Servicen</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />ECG Technician</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Biomedical Technicians</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />ICU, CCU Services</li>
                                    </ul>
                                </div>
                            )
                        }

                        <div className="col-md-4">
                            <img
                                src="https://apisparamedicalinstitute.com/assets/images/new/course/1.jpg"
                                className="img-fluid"
                                alt="Radiology and Medical Imaging Technology"
                            />
                            <div className="row mt-5 bg-white" style={{ height: "200px", margin: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div>
                                    <div>
                                        <Link to='/join'> <button style={{ padding: "10px", background: "#273089", color: "white", border: "none", width: "100%", marginBottom: "20px", fontWeight: "bold" }}>JOIN NOW</button></Link>
                                    </div>
                                    <div>
                                        <Link to='/contact'><button style={{ padding: "10px", color: "#273089", borderColor: "#273089", width: "100%", fontWeight: "bold", background: "white" }} >ENQUIRE NOW</button></Link>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </div>
                <br /><br />
            </div>
            <LastFooter />


        </>
    )
}

export default CourseSix
