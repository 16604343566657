import React from 'react'
import './thirdComponent.css'
import { Link } from 'react-router-dom'
import AnimatedNumber from "animated-number-react";

function ThirdComponent() {


    return (
        <div className='container main-div'>

            <div className='row second-main'>

                <div className='col-lg-4 p-5 welcome-box' style={{ background:"rgb(243, 248, 249)", padding: "20px" }}>
                    <div data-aos="fade-up"
                        data-aos-duration="2000">
                        <h5 style={{ color: "#273089" }}>WELCOME TO</h5>
                        <h3 style={{ color: "rgb(88, 85, 85)" }}>IRIS Institute of Paramedical Science</h3>
                        <p style={{ color: "rgb(88, 85, 85)" }}>A hub of excellence in healthcare education. We are a leading institution committed to providing top-notch education and training in the field of paramedical. With a strong focus on practical training and a dedicated faculty, we empower students to become skilled paramedical professionals.</p>
                        <Link to='/about' ><button className='readmore-btn' style={{textDecoration:"none",color:"white",fontWeight:"bold",}}>Read more</button></Link>
                    </div>

                </div>

                <div className='col-lg-6'>
                    <div className="row couter-box">
                        <div className="flex-item" style={{ background: "#fbf8cc" }}>
                            <div className="counter-item">
                                <h2 data-aos="fade-up">
                                    <AnimatedNumber
                                        value={'1000'}
                                        formatValue={(value) => value.toFixed(2)}

                                    />+
                                </h2> <h5>Students</h5>
                            </div>
                        </div>
                        <div className="flex-item" style={{ background: "#e9fbd5" }}> <div className="counter-item">
                            <h2 data-aos="fade-up">
                                <AnimatedNumber
                                    value={'3.50'}
                                    formatValue={(value) => value.toFixed(2)}
                                /></h2> <h5>Average CGPA</h5>
                        </div>
                        </div>
                        <div className="flex-item" style={{ background: "#dcf1fd" }}> <div className="counter-item">
                            <h2 data-aos="fade-up">  <AnimatedNumber
                                value={'95'}
                                formatValue={(value) => value.toFixed(2)}
                            />%</h2>
                            <h5>Graduates</h5></div>
                        </div>
                    </div>

                    <div className="image-grid row mt-5 row-gap-3 ps-2 pe-2">

                        <div className="img-div col-lg-6 d-flex justify-content-center ps-0">
                            <img src="https://apisparamedicalinstitute.com/assets/images/about/style2/grid1.jpg" className='imgGrid' width={'100%'} height={'250px'} alt="" />
                        </div>

                        <div className="img-div col-lg-6 d-flex justify-content-center p-0">
                            <img src="https://apisparamedicalinstitute.com/assets/images/about/style2/grid2.jpg" className='imgGrid' width={'100%'} height={'250px'} alt="" />
                        </div>

                    </div>


                </div>

            </div>

        </div>
    )
}

export default ThirdComponent
