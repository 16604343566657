import React from 'react'
import './address.css'
import { FaLocationDot } from "react-icons/fa6"
import { MdOutlineEmail } from "react-icons/md";
import { IoIosContact } from "react-icons/io";
import { Link } from 'react-router-dom';

function Address() {
    return (
        <div className="container" style={{ padding: "20px" }}>
            <div className="row">
                {/* First Box with three columns */}
                <div className="col-lg-4 pt-5" >
                    <div className="info-box d-flex align-items-center" style={{ background: "#f9f9f9", height: "200px" }}>
                        <div className="logo-section">
                            <FaLocationDot size={'50px'} color='#273089' />
                        </div>
                        <div className="ms-3">
                            <p style={{ color: "#505050" }}>
                                Address
                                KIMS Sunrise Hospital, NH 66, Ashwini Nagar, Kasaragod</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 pt-5" >
                    <div className="info-box d-flex align-items-center" style={{ background: "#f9f9f9", height: "200px" }}>
                        <div className="logo-section">
                            <MdOutlineEmail size={'50px'} color='#273089' />
                        </div>
                        <div className="ms-0">
                            <p style={{ color: "#505050" }}>
                                Email Address
                            </p>
                            <p style={{ color: "#505050" }}>admin@apisparamedicalinstitute.com</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 pt-5">
                    <div className="info-box d-flex align-items-center" style={{ background: "#f9f9f9", height: "200px" }}>
                        <div className="logo-section">
                            <IoIosContact size={'50'} color='#273089' />
                        </div>
                        <div className="ms-3">
                            <p>
                                Phone Number
                            </p>
                            <p>+91 9744 63 63 63</p>
                            <p>+91 9744 63 63 63</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Second Box with Map and Form */}
            <div className="row mt-5">
                <div className="col-lg-6">
                    <div className="map-box">
                        <iframe title="google-map" width="100%" height="400" id="gmap_canvas"
                            src="https://maps.google.com/maps?q=kasaragod%20iris&t=&z=13&ie=UTF8&iwloc=&output=embed"
                            frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-box" style={{ background: "#F9F8F8", padding: "30px" }}>
                        <h2>Get In Touch</h2>
                        <p>Get in touch to learn more about our courses and how we can support your goals.</p>
                        <form className='mt-4'>
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input type="text" className="form-control" id="name" required />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="email" className="form-control" id="email" required />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <label htmlFor="phone" className="form-label">Phone</label>
                                    <input type="tel" className="form-control" id="phone" required />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="subject" className="form-label">Subject</label>
                                    <input type="text" className="form-control" id="subject" required />
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="message" className="form-label">Message</label>
                                <textarea className="form-control" id="message" rows="5" required></textarea>
                            </div>
                            <button style={{ background: "#273089", color: "white", width: "100%" }} type="submit" className="btn">Send Message</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Address
