import React from 'react';
import { Card, CardBody, CardTitle, CardText, } from 'reactstrap';
import { TfiEmail } from "react-icons/tfi";
import { Link } from 'react-router-dom';
function Principal() {
    return (
            <div className="container row pb-4">
                <div className="col-lg-6" style={{display:"flex",justifyContent:"center",paddingTop:"20px",}}>
                    <Card className='shadow-lg' style={{ width: '23.5rem', padding: "30px", border: "none",height:"fit-content" }}>
                        <img
                            alt="Sample"
                            src="https://apisparamedicalinstitute.com/assets/images/new/t4.jpg"
                            style={{ borderRadius: "10px" }}
                            height={'320vh'}
                        />
                        <CardBody>
                            <CardTitle tag="h5" style={{ textAlign: "center"}}>
                                Fathimath Saujana PK
                            </CardTitle>
                            <CardText style={{ textAlign: "center" }}>
                                <div>
                                    <span>
                                        <TfiEmail />
                                    </span>
                                    <Link style={{ textDecoration: "none", color: "black" }}> principal@apisparamedicalinstitute.com</Link>
                                </div>
                            </CardText>
                        </CardBody>
                    </Card>
                </div>
                <div className="col-lg-6">
                    <h2 className='mt-4'>Principal’s Message</h2>
                    <br />
                    <p style={{ color: "#626262" }}>
                        Welcome to IRIS Institute of Paramedical Science. We believe that healthcare is about more than just treating illness - it's about empowering people to live healthy, fulfilling lives. That's why we are committed to providing a comprehensive education that goes beyond the technical skills of healthcare and emphasizes the importance of empathy, compassion, and patient-centered care.
                        <br /><br />
                        Our programs are designed to prepare students for a wide range of careers in healthcare, from medical laboratory and Imaging technology to Cardiac Care Technology and more. With our experienced faculty, state-of-the-art facilities, and rigorous curriculum, we strive to provide our students with a top-quality education that is both relevant and up-to-date. Whether you are just starting out on your educational journey or looking to take your career to the next level, we are here to help you achieve your goals and make a positive impact on the world around you.
                    </p>
                </div>
            </div>

    )
}

export default Principal
