import React from 'react'
import courseImg1 from '../images/courseImg.jpg'
import courseImg2 from '../images/courseImg2.jpg'
import courseImg3 from '../images/courseImg3.jpg'
import { Link } from 'react-router-dom'
import './Course.css'

function Course() {
  return (
    <div style={{ background: "#f3f8f9" }}>
      <div className="container" style={{ padding: "20px" }}>
        <div className="course-title text-center">
          <h2>Our Courses</h2>
        </div>


        <div className="middle-big-box row mt-5">
          <div className="col-lg-4 col-md-6 mb-2 course-card">
            <div className="images-container">
              <img src="https://apisparamedicalinstitute.com/assets/images/new/course/1.jpg" alt="" className="img-fluid" />
              <div className="initial-overlay">
                <h4 style={{ fontWeight: "bold" }}>Radiology and Medical Imaging Technology</h4>
              </div>
              <div className="hover-overlay">
                <h4 style={{ fontWeight: "bold" }}>Radiology and Medical Imaging Technology</h4>
                <p style={{ fontWeight: "bold" }}>Radiology and Medical Imaging Technology technicians are ...</p>
                <Link to='/course1' style={{textDecoration:"none",color:"black"}}>
                <button class="cta">
                  <span class="hover-underline-animation">Read More</span>
                  <svg
                    id="arrow-horizontal"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="10"
                    viewBox="0 0 46 16"
                  >
                    <path
                      id="Path_10"
                      data-name="Path 10"
                      d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                      transform="translate(30)"
                    ></path>
                  </svg>
                </button>
                </Link>
              </div>
            </div>
          </div>


          <div className="col-lg-4 col-md-6 mb-2 course-card">
            <div className="images-container">
            <img src="https://apisparamedicalinstitute.com/assets/images/new/course/2.jpg" alt="" className="img-fluid" />
              <div className="initial-overlay">
                <h4 style={{ fontWeight: "bold" }}>Operation Theatre Technology</h4>
              </div>
              <div className="hover-overlay">
                <h4 style={{ fontWeight: "bold" }}>Operation Theatre Technology</h4>
                <p style={{ fontWeight: "bold" }}>Operation Theatre Technology will provide thorough ... </p>
                <Link to='/course2' style={{textDecoration:"none",color:"black"}}>
                <button class="cta">
                  <span class="hover-underline-animation">Read More</span>
                  <svg
                    id="arrow-horizontal"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="10"
                    viewBox="0 0 46 16"
                  >
                    <path
                      id="Path_10"
                      data-name="Path 10"
                      d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                      transform="translate(30)"
                    ></path>
                  </svg>
                </button>
                </Link>
              </div>
            </div>
          </div>




          <div className="col-lg-4 col-md-6 mb-2 course-card">
            <div className="images-container">
            <img src="https://apisparamedicalinstitute.com/assets/images/new/course/3.jpg" alt="" className="img-fluid" />
            <div className="initial-overlay">
                <h4 style={{ fontWeight: "bold" }}>Medical Laboratory Technology</h4>
              </div>
              <div className="hover-overlay">
                <h4 style={{ fontWeight: "bold" }}>Medical Laboratory Technology</h4>
                <p style={{ fontWeight: "bold" }}>Medical Laboratory Technology prepares students to become important members ... </p>
                <Link to='/course3' style={{textDecoration:"none",color:"black"}}>
                <button class="cta">
                  <span class="hover-underline-animation">Read More</span>
                  <svg
                    id="arrow-horizontal"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="10"
                    viewBox="0 0 46 16"
                  >
                    <path
                      id="Path_10"
                      data-name="Path 10"
                      d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                      transform="translate(30)"
                    ></path>
                  </svg>
                </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mb-2 course-card">
            <div className="images-container">
            <img src={courseImg1} alt="" className="img-fluid" />
            <div className="initial-overlay">
                <h4 style={{ fontWeight: "bold" }}>Optometry</h4>
              </div>
              <div className="hover-overlay">
                <h4 style={{ fontWeight: "bold" }}>Optometry Technology</h4>
                <p style={{ fontWeight: "bold" }}>Optometry Technology trains allied healthcare professionals with ... </p>
                <Link to='/course4' style={{textDecoration:"none",color:"black"}}>
                <button class="cta">
                  <span class="hover-underline-animation">Read More</span>
                  <svg
                    id="arrow-horizontal"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="10"
                    viewBox="0 0 46 16"
                  >
                    <path
                      id="Path_10"
                      data-name="Path 10"
                      d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                      transform="translate(30)"
                    ></path>
                  </svg>
                </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mb-2 course-card">
            <div className="images-container">
            <img src={courseImg2} alt="" className="img-fluid" />
            <div className="initial-overlay">
                <h4 style={{ fontWeight: "bold" }}>Dialysis Technology</h4>
              </div>
              <div className="hover-overlay">
                <h4 style={{ fontWeight: "bold" }}>Dialysis Technology</h4>
                <p style={{ fontWeight: "bold" }}>Dialysis Technology is a three year degree course in the School of Allied Health ... </p>
                <Link to='/course5' style={{textDecoration:"none",color:"black"}}>
                <button class="cta">
                  <span class="hover-underline-animation">Read More</span>
                  <svg
                    id="arrow-horizontal"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="10"
                    viewBox="0 0 46 16"
                  >
                    <path
                      id="Path_10"
                      data-name="Path 10"
                      d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                      transform="translate(30)"
                    ></path>
                  </svg>
                </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mb-2 course-card">
            <div className="images-container">
            <img src={courseImg3} alt="" className="img-fluid" />
            <div className="initial-overlay">
                <h4 style={{ fontWeight: "bold" }}>Cardiac Care Technology</h4>
              </div>
              <div className="hover-overlay">
                <h4 style={{ fontWeight: "bold" }}>Cardiac Care Technology</h4>
                <p style={{ fontWeight: "bold" }}>Cardiac Care Technology is a 3-year full-time undergraduate course divided ... </p>
                <Link to='/course6' style={{textDecoration:"none",color:"black"}}>
                <button class="cta">
                  <span class="hover-underline-animation">Read More</span>
                  <svg
                    id="arrow-horizontal"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="10"
                    viewBox="0 0 46 16"
                  >
                    <path
                      id="Path_10"
                      data-name="Path 10"
                      d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                      transform="translate(30)"
                    ></path>
                  </svg>
                </button>
                </Link>
              </div>
            </div>
          </div>





        </div>

      </div>
    </div>
  )
}

export default Course


