import React from 'react';
import './campusimage.css' // Import the CSS file

function CampusImage() {
  return (
    <div>
      <div className="container p-4">
        <div className="row g-4"> {/* Add the Bootstrap gap class g-4 for gap */}
          <div className="col-lg-4 col-md-6">
            <img className="campus-img" src="https://apisparamedicalinstitute.com/assets/images/new/gallery/1.jpg" alt="Campus" />
          </div>
          <div className="col-lg-4 col-md-6">
            <img className="campus-img" src="https://apisparamedicalinstitute.com/assets/images/new/gallery/1.jpg" alt="Campus" />
          </div>
          <div className="col-lg-4 col-md-6">
            <img className="campus-img" src="https://apisparamedicalinstitute.com/assets/images/new/gallery/1.jpg" alt="Campus" />
          </div>
          <div className="col-lg-4 col-md-6">
            <img className="campus-img" src="https://apisparamedicalinstitute.com/assets/images/new/gallery/1.jpg" alt="Campus" />
          </div>
          <div className="col-lg-4 col-md-6">
            <img className="campus-img" src="https://apisparamedicalinstitute.com/assets/images/new/gallery/1.jpg" alt="Campus" />
          </div>
          <div className="col-lg-4 col-md-6">
            <img className="campus-img" src="https://apisparamedicalinstitute.com/assets/images/new/gallery/1.jpg" alt="Campus" />
          </div>
          <div className="col-lg-4 col-md-6">
            <img className="campus-img" src="https://apisparamedicalinstitute.com/assets/images/new/gallery/1.jpg" alt="Campus" />
          </div>
          <div className="col-lg-4 col-md-6">
            <img className="campus-img" src="https://apisparamedicalinstitute.com/assets/images/new/gallery/1.jpg" alt="Campus" />
          </div>
          <div className="col-lg-4 col-md-6">
            <img className="campus-img" src="https://apisparamedicalinstitute.com/assets/images/new/gallery/1.jpg" alt="Campus" />
          </div>
          <div className="col-lg-4 col-md-6">
            <img className="campus-img" src="https://apisparamedicalinstitute.com/assets/images/new/gallery/1.jpg" alt="Campus" />
          </div>
          <div className="col-lg-4 col-md-6">
            <img className="campus-img" src="https://apisparamedicalinstitute.com/assets/images/new/gallery/1.jpg" alt="Campus" />
          </div>
          <div className="col-lg-4 col-md-6">
            <img className="campus-img" src="https://apisparamedicalinstitute.com/assets/images/new/gallery/1.jpg" alt="Campus" />
          </div>
          <div className="col-lg-4 col-md-6">
            <img className="campus-img" src="https://apisparamedicalinstitute.com/assets/images/new/gallery/1.jpg" alt="Campus" />
          </div>
          <div className="col-lg-4 col-md-6">
            <img className="campus-img" src="https://apisparamedicalinstitute.com/assets/images/new/gallery/1.jpg" alt="Campus" />
          </div>
          <div className="col-lg-4 col-md-6">
            <img className="campus-img" src="https://apisparamedicalinstitute.com/assets/images/new/gallery/1.jpg" alt="Campus" />
          </div>
          <div className="col-lg-4 col-md-6">
            <img className="campus-img" src="https://apisparamedicalinstitute.com/assets/images/new/gallery/1.jpg" alt="Campus" />
          </div>
          <div className="col-lg-4 col-md-6">
            <img className="campus-img" src="https://apisparamedicalinstitute.com/assets/images/new/gallery/1.jpg" alt="Campus" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampusImage;
