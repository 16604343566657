import React from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './courseOne.css'
import { VscActivateBreakpoints } from "react-icons/vsc";
import LastFooter from '../../home/footer/LastFooter';
import LoadingSpinner from '../../LodingSpinner/LodingSpinner';

function CourseTwo() {
    const [click, setClick] = useState('graduate')

    const graduate = () => {
        setClick('graduate')
    }

    const postGraduate = () => {
        setClick('postGraduate')
    }

    const Diploma = () => {
        setClick('Diploma')
    }


    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate a loading delay
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000); // Adjust the timeout as needed

        // Cleanup the timer
        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return <LoadingSpinner />;
    }



    return (
        <>
            <div className='first-box'>
                <div className="first-box-title">
                    <div>
                        <h1 className='text-white'>Operation Theatre Technology (OTT)</h1>
                        <div className='d-flex justify-content-center text-white'>
                            <Link to='/' className='me-2' style={{ textDecoration: "none", color: "white" }} > Home </Link>// OTT
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ background: "#f3f8f9", padding: "20px", paddingTop: "40px" }}>
                <div className="container">
                    <div>
                        <button className='me-1 courseBtn'
                            style={click === 'graduate' ? { background: "#273089", color: "white", fontWeight: "bold", border: "none", padding: "10px", width: "90px" } : { fontWeight: "bold", border: "none", padding: "10px", width: "90px" }}
                            onClick={graduate}
                        >
                            Graduate
                        </button>
                        <button
                            className='me-1 courseBtn'
                            style={click === 'postGraduate' ? { background: "#273089", color: "white", fontWeight: "bold", border: "none", padding: "10px", width: "130px" } : { fontWeight: "bold", border: "none", padding: "10px", width: "130px", }}
                            onClick={postGraduate}
                        >
                            Post Graduate
                        </button>
                        <button
                            className='courseBtn'
                            style={click === 'Diploma' ? { background: "#273089", color: "white", fontWeight: "bold", border: "none", padding: "10px", width: "90px" } : { fontWeight: "bold", border: "none", padding: "10px", width: "90px" }}
                            onClick={Diploma}
                        >
                            Diploma
                        </button>

                    </div>
                    <div className="row mt-1 row-gap-3">
                        {click === 'graduate' && (
                            <div className="col-md-8 bg-white p-4">
                                <h4>B.Voc in Operation Theatre Technology (OTT)</h4>
                                <p style={{ color: "#626262" }}>
                                    B.Voc in Operation Theatre Technology will provide thorough knowledge regarding working in operation theatre, along with miscellaneous topics like Infection control, Biomedical waste management, etc. Students will be able to prepare the operation theatre, assisting surgeons and nurses in various procedures before, during, and after surgery. Instructional methods are varied, including classrooms and labs. Individuals will also be responsible for maintaining equipment, sterilization, and personal hygiene.
                                </p>
                                <h3>Jop Scope</h3>
                                <h4>for a B.Voc OTT  Graduate</h4>
                                <ul>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Anaesthetist Consultant</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> OT Associate Consultant</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> OT Technician</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Teacher & Lecturer</li>

                                </ul>
                            </div>
                        )}

                        {click === 'postGraduate' && (
                            <div className="col-md-8 bg-white p-4">
                                <h4>PPost Graduate in Operation Theatre Technology (OTT)</h4>
                                <p style={{ color: "#626262" }}>
                                    Post Graduate in Operation Theatre Technology will provide thorough knowledge regarding working in operation theatre, along with miscellaneous topics like Infection control, Biomedical waste management, etc. Students will be able to prepare the operation theatre, assisting surgeons and nurses in various procedures before, during, and after surgery. Instructional methods are varied, including classrooms and labs. Individuals will also be responsible for maintaining equipment, sterilization, and personal hygiene.                                </p>
                                <h3>Jop Scope</h3>
                                <h4>for Post Graduate OTT Graduate</h4>
                                <ul>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Anaesthetist Consultant</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> OT Associate Consultant</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> OT Technician</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Teacher & Lecturer</li>

                                </ul>
                            </div>

                        )}

                        {
                            click === 'Diploma' && (
                                <div className="col-md-8 bg-white p-4">
                                    <h4>Diploma in Operation Theatre Technology (OTT)</h4>
                                    <p style={{ color: "#626262" }}>
                                        Diploma in Operation Theatre Technology will provide thorough knowledge regarding working in operation theatre, along with miscellaneous topics like Infection control, Biomedical waste management, etc. Students will be able to prepare the operation theatre, assisting surgeons and nurses in various procedures before, during, and after surgery. Instructional methods are varied, including classrooms and labs. Individuals will also be responsible for maintaining equipment, sterilization, and personal hygiene.                                    </p>
                                    <h3>Jop Scope</h3>
                                    <h4>for Diploma in OTT</h4>
                                    <ul>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Anaesthetist Consultant</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> OT Associate Consultant</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> OT Technician</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Teacher & Lecturer</li>
                                    </ul>
                                </div>
                            )
                        }

                        <div className="col-md-4">
                            <img
                                src="https://apisparamedicalinstitute.com/assets/images/new/course/1.jpg"
                                className="img-fluid"
                                alt="Radiology and Medical Imaging Technology"
                            />
                            <div className="row mt-5 bg-white" style={{ height: "200px", margin: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div>
                                    <div>
                                        <Link to='/join'> <button style={{ padding: "10px", background: "#273089", color: "white", border: "none", width: "100%", marginBottom: "20px", fontWeight: "bold" }}>JOIN NOW</button></Link>
                                    </div>
                                    <div>
                                        <Link to='/contact'><button style={{ padding: "10px", color: "#273089", borderColor: "#273089", width: "100%", fontWeight: "bold", background: "white" }} >ENQUIRE NOW</button></Link>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </div>
                <br /><br />
            </div>
            <LastFooter />


        </>
    )
}


export default CourseTwo
