import React from 'react';
import './Spinner.css';

function LoadingSpinner() {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", position: 'fixed',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: "#fff",
            zIndex: 10000}}>

            <div className='loader-container'>
                <div className="loader">

                </div>
            </div>
            <img src="./images/iris-2.png" alt="" width={'100px'} />
        </div>
    );
}

export default LoadingSpinner;
