import React from 'react'
import './footer.css'
import { RiFacebookCircleFill } from "react-icons/ri";
import { IoLogoTwitter } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa";

function LastFooter() {
  return (
    <div className="footer">
      <div className="footer-left">
        <img src={'./images/iris-1.png'} alt="Logo" className="footer-logo" />
      </div>
      <div className="footer-center">
        <span className='text-dark'>© 2023 All Rights Reserved.</span>
      </div>
      <div className="footer-right">
        <RiFacebookCircleFill size={'20px'} color='black'/>
        <IoLogoTwitter size={'20px'} color='black'/>
        <FaInstagram size={'20px'} color='black'/>
      </div>
    </div>    
  )
}

export default LastFooter
