import React from 'react';
import { Card, CardBody, CardTitle, CardText, } from 'reactstrap';
import { TfiEmail } from "react-icons/tfi";
import { Link } from 'react-router-dom';


function CourseDirector() {
  return (
    <div className="container row">
        <div className="col-lg-6" style={{display:"flex",justifyContent:"center",paddingTop:"20px"}}>
            <Card className='shadow-lg' style={{ width: '23rem', padding: "30px", border: "none",height:"fit-content" }}>
                <img
                    alt="Sample"
                    src="https://apisparamedicalinstitute.com/assets/images/new/t2.jpg"
                    style={{ borderRadius: "10px" }}
                    height={'320vh'}
                />
                <CardBody>
                    <CardTitle tag="h5" style={{ textAlign: "center" }}>
                    Mr. Abu Yazar KP
                    </CardTitle>
                    <CardText style={{ textAlign: "center" }}>
                        <div>
                            <span>
                                <TfiEmail />
                            </span>
                            <Link style={{ textDecoration: "none", color: "black" }}>  director@apisparamedicalinstitute.com</Link>
                        </div>
                    </CardText>
                </CardBody>
            </Card>
        </div>
        <div className="col-lg-6">
            <h2 className='mt-4'>Course Director’s Message</h2>
            <h5>The Institution provides Paramedical Education strictly as per the norms laid down by Lingaya's Universities, at a cost effective fee and innovative learning methodology with International Paramedical standards.</h5>
            <br />
            <p style={{color:"#626262"}}>
            Welcome to IRIS Institute of Paramedical Science. With the inspiration of Florence Nightingale, our college wishes to light the lamp of service and dedication in every heart. The institution provides paramedical education strictly as per the norms laid down by Lingaya's Universities at a cost-effective fee and with innovative learning methodology, meeting international paramedical standards. We offer career opportunities for students in India and abroad. Our main objective at the institution is to produce competent and skilled paramedical professionals who work with the spirit of service to humanity. May you see your dreams and fulfill them with the everlasting blessings of the Almighty.
            </p>
        </div>
    </div>

  )
}

export default CourseDirector
