import React from 'react'
import { Link } from 'react-router-dom'

function JoinFirst() {
  return (
    <div className='first-box'>
    <div className="first-box-title">
    <div>
          <h1 className='text-white'>Join Now</h1>
          <div className='d-flex justify-content-center text-white'>
            <Link to='/' className='me-2' style={{ textDecoration: "none", color: "white" }} > Home </Link> // Join Now
          </div>
        </div>
    </div>
 </div>
  )
}

export default JoinFirst
