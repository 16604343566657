import React from 'react';
import gridImg from '../images/gridImg.jpg';
import './grid.css';

function Grid() {
  return (
    <div className='row grids-main'>
      <div className='col-lg-3 col-md-6 grid-item bg-overlay-1' style={{ backgroundImage: `url(${gridImg})`,display:"flex", justifyContent:"center",alignItems:"center" }}>
        <div className="grid-item-content">
          <img src="https://apisparamedicalinstitute.com/assets/images/services/icons/1.png" style={{paddingLeft:"40px"}} alt="" />
          <h4 style={{color:"white"}}>Campus Life</h4>
        </div>
      </div>
      <div className='col-lg-3 col-md-6 grid-item bg-overlay-2' style={{ backgroundImage: `url(${gridImg})`,display:"flex", justifyContent:"center",alignItems:"center" }}>
        <div className="grid-item-content">
        <img src="https://apisparamedicalinstitute.com/assets/images/services/icons/2.png"  style={{paddingLeft:"30px"}} alt="" />
        <h4 style={{color:"white"}}>Graduation</h4>
        </div>
      </div>
      <div className='col-lg-3 col-md-6 grid-item bg-overlay-1' style={{ backgroundImage: `url(${gridImg})`,display:"flex", justifyContent:"center",alignItems:"center" }}>
        <div className="grid-item-content">
        <img src="https://apisparamedicalinstitute.com/assets/images/services/icons/3.png"  style={{paddingLeft:"20px"}} alt="" />
        <h4 style={{color:"white"}}>Athletics</h4>
        </div>
      </div>
      <div className='col-lg-3 col-md-6 grid-item bg-overlay-2' style={{ backgroundImage: `url(${gridImg})`,display:"flex", justifyContent:"center",alignItems:"center" }}>
        <div className="grid-item-content">
        <img src="https://apisparamedicalinstitute.com/assets/images/services/icons/1.png"  style={{paddingLeft:"10px"}}  alt="" />
        <h4 style={{color:"white"}}>Social</h4>
        </div>
      </div>
    </div>
  );
}

export default Grid;
