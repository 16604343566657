import React from 'react'
import './lastComponent.css'
import { FaRegUserCircle } from "react-icons/fa";
import { Link } from 'react-router-dom';

function LastComponent() {
  return (
    <div className='row no-gutters'>
      <div className="col-lg-6 image-container" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div className='d-flex' style={{ color: "white", fontWeight: "bold", marginTop: "300px", marginRight: "100px" }}>
          <FaRegUserCircle color='white' size='50px' />
          <h3 style={{ marginLeft: "20px" }}><Link to='/campus' style={{ color: "white", textDecoration: "none",fontWeight:"bold" }}>Campus Life at <br />IRIS Institute</Link></h3>
        </div>
      </div>
      <div
        className="col-lg-6 content-container p-5" style={{ backgroundImage: `url(https://apisparamedicalinstitute.com/assets/images/cta/style2/right-bg.jpg)` }}>
        <div data-aos="fade-up"
          data-aos-duration="2000"  style={{ width: "100%" }}>
          <h2>Admission Open for 2024</h2>
          <p>IRIS Institute of Paramedical Science is all about experimental learning and building an ever-expanding knowledge base involving knowledge partners and communities. IRIS is where information and ideas flow freely in open and friendly atmosphere a "Knowledge Hub of Health Care Fraternity" in which students learn share and shine.</p>
          <br />
          <button style={{ background: "black", color: "white", border: "none", borderRadius: "3px", padding: "15px", fontWeight: "bold" }}>
          <Link to='/join' style={{textDecoration:"none",color:"white"}}>Apply Now</Link> 
          </button>
        </div>

      </div>
    </div>
  )
}

export default LastComponent
