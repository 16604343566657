import React from 'react';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { TfiEmail } from "react-icons/tfi";
import { Link } from 'react-router-dom';

function Director() {
    return (
        <div style={{background:"#f3f8f9"}}>
            <div className="container row pt-3">
                <div className="col-lg-6" style={{display:"flex",justifyContent:"center",}}>
                    <Card className='shadow-lg' style={{ width: '23rem', padding: "30px", border: "none",height:"fit-content" }}>
                        <img
                            alt="Sample"
                            src="https://apisparamedicalinstitute.com/assets/images/new/t1.jpg"
                            style={{ borderRadius: "10px" }}
                            height={'320vh'}
                        />
                        <CardBody>
                            <CardTitle tag="h5" style={{ textAlign: "center" }}>
                                Dr. Muhammed Saleem
                            </CardTitle>
                            <CardText style={{ textAlign: "center" }}>
                                <p style={{ color: "#626262" }}>(MBBS, MS (Gen.Surgery), MRCS(UK), DNB (Urology)</p>
                                <div>
                                    <span>
                                        <TfiEmail />
                                    </span>
                                    <Link style={{ textDecoration: "none", color: "black" }}> director@apisparamedicalinstitute.com</Link>
                                </div>
                            </CardText>
                        </CardBody>
                    </Card>
                </div>
                <div className="col-lg-6">
                    <h2 className='mt-4'>Director’s Message</h2>
                    <h5>We also recognize the importance of providing a supportive and assimilative learning environment using innovative teaching and learning technological advances.</h5>
                    <br />
                    <p style={{color:"#626262"}}>
                        Paramedical sciences are an upcoming vocational course with a wide range of professional and educational career opportunities. At IRIS Institute of Paramedical Science, we strive to achieve quality in paramedical education while ensuring the overall development of our students. We recognize the importance of providing a supportive and assimilative learning environment that utilizes innovative teaching and learning technologies.
                        <br /><br />
                        We also offer an optimum blend of technical and personality development skills. IRIS Institute of Paramedical Science believes that paramedical science must seek students with uncommon ability and commitment and provide them with an education that will help them make the most of the gifts they already possess.
                        <br /><br />
                        We have worked to build an institute with the highest reputation that provides the most competitive learning infrastructure in the field. Our institute is served by renowned faculty known for their clinical skills, with a large and varied selection of carefully guided practical experiences. IRIS Institute of Paramedical Science has always defined its mission for excellence with a human touch by promoting better health for all people. With a clear understanding of the need and importance of such educational facilities, the institute is committed to a non-commercial approach.
                        <br /><br />
                        We encourage you to avail of this opportunity and join the wonderful and rewarding profession.
                    </p>
                </div>
            </div>
            </div>
    );
}

export default Director;
