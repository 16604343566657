// MySwiper.jsx
import React from 'react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';
import swiperImg from '../images/swiperImg.jpg';
import swiperImg2 from '../images/swiperimg2.jpg';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination } from 'swiper/modules';
import { Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';
import './swiper.css';

function  MySwiper() {
  return (
    <div className='content' style={{ height: '540px' }}>
      <SwiperComponent
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Navigation, Pagination, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide
          style={{
            backgroundImage: `url(${swiperImg2})`,
            width: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className="swiper-content">
            <div data-aos="fade-right">
              <p className="swiper-text large"> Welcome to</p>
            </div>
            <div data-aos="fade-left">
              <h1 className="swiper-title" style={{fontWeight:"bold"}}>IRIS Institute</h1>
            </div>
            <button
              data-aos="fade-up"
              data-aos-duration="3000"
              className="swiper-button"
            >
              <Link to={'/about'} style={{textDecoration:"none",color:"white",fontWeight:"bold"}}>Read More</Link>
            </button>
          </div>
        </SwiperSlide>
        <SwiperSlide
          style={{
            backgroundImage: `url(${swiperImg})`,
            width: '100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className="swiper-content">
            <div data-aos="fade-right">
              <p className="swiper-text large"> Where Passion Meets Excellence</p>
            </div>
            <div data-aos="fade-left">
              <h1 className="swiper-title" style={{fontWeight:"bold"}}>IRIS Institute</h1>
            </div>
            <button
              data-aos="fade-up"
              data-aos-duration="3000"
              className="swiper-button"
            >
              <Link to={'/about'} style={{textDecoration:"none",color:"white",fontWeight:"bold"}}>Read More</Link>
            </button>
          </div>
        </SwiperSlide>
      </SwiperComponent>
    </div>
  );
}

export default MySwiper;
