import React from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './courseOne.css'
import { VscActivateBreakpoints } from "react-icons/vsc";
import LastFooter from '../../home/footer/LastFooter';
import LoadingSpinner from '../../LodingSpinner/LodingSpinner';

function CourseThree() {
    const [click, setClick] = useState('graduate')

    const graduate = () => {
        setClick('graduate')
    }

    const postGraduate = () => {
        setClick('postGraduate')
    }

    const Diploma = () => {
        setClick('Diploma')
    }



    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate a loading delay
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000); // Adjust the timeout as needed

        // Cleanup the timer
        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return <LoadingSpinner />;
    }



    return (
        <>
            <div className='first-box'>
                <div className="first-box-title">
                    <div>
                        <h1 className='text-white'>Medical Laboratory Technology (MLT)</h1>
                        <div className='d-flex justify-content-center text-white'>
                            <Link to='/' className='me-2' style={{ textDecoration: "none", color: "white" }} > Home </Link>// MLT
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ background: "#f3f8f9", padding: "20px", paddingTop: "40px" }}>
                <div className="container">
                    <div>
                        <button className='me-1 courseBtn'
                            style={click === 'graduate' ? { background: "#273089", color: "white", fontWeight: "bold", border: "none", padding: "10px", width: "90px" } : { fontWeight: "bold", border: "none", padding: "10px", width: "90px" }}
                            onClick={graduate}
                        >
                            Graduate
                        </button>
                        <button
                            className='me-1 courseBtn'
                            style={click === 'postGraduate' ? { background: "#273089", color: "white", fontWeight: "bold", border: "none", padding: "10px", width: "130px" } : { fontWeight: "bold", border: "none", padding: "10px", width: "130px", }}
                            onClick={postGraduate}
                        >
                            Post Graduate
                        </button>
                        <button
                            className='courseBtn'
                            style={click === 'Diploma' ? { background: "#273089", color: "white", fontWeight: "bold", border: "none", padding: "10px", width: "90px" } : { fontWeight: "bold", border: "none", padding: "10px", width: "90px" }}
                            onClick={Diploma}
                        >
                            Diploma
                        </button>

                    </div>
                    <div className="row mt-1 row-gap-3">
                        {click === 'graduate' && (
                            <div className="col-md-8 bg-white p-4">
                                <h4>Medical Laboratory Technology (MLT)</h4>
                                <p style={{ color: "#626262" }}>
                                    B.Voc in Medical Laboratory Technology prepares students to become important members of any clinical laboratory team. Laboratory technicians collect fluid, tissue, and blood samples to perform a variety of tests under the supervision of a pathologist. They may work in hospitals, diagnostic labs, blood banks, and medical clinics, and they work within a team of medical professionals, each performing tasks essential to successful patient care.
                                </p>
                                <h3>Jop Scope</h3>
                                <h4>for B.Voc MLT</h4>
                                <ul>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Lab Manager</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Lab Assistan</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Lab Analyst</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Lab Incharge</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Lab Technician</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Lab Technologist</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Lab Supervisor</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Instructor / Tutor</li>
                                </ul>
                            </div>
                        )}

                        {click === 'postGraduate' && (
                            <div className="col-md-8 bg-white p-4">
                                <h4>Post Graduate in Medical Laboratory Technology (MLT)</h4>
                                <p style={{ color: "#626262" }}>
                                    Post Graduate in Medical Laboratory Technology prepares students to become important members of any clinical laboratory team. Laboratory technicians collect fluid, tissue, and blood samples to perform a variety of tests under the supervision of a pathologist. They may work in hospitals, diagnostic labs, blood banks, and medical clinics, and they work within a team of medical professionals, each performing tasks essential to successful patient care.</p>
                                <h3>Jop Scope</h3>
                                <h4>for Post Graduate MLT</h4>
                                <ul>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Lab Manager</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Lab Assistan</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Lab Analyst</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Lab Incharge</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Lab Technician</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Lab Technologist</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Lab Supervisor</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Instructor / Tutor</li>
                                </ul>
                            </div>

                        )}

                        {
                            click === 'Diploma' && (
                                <div className="col-md-8 bg-white p-4">
                                    <h4>Diploma in Medical Laboratory Technology (MLT)</h4>
                                    <p style={{ color: "#626262" }}>
                                        Diploma in Medical Laboratory Technology prepares students to become important members of any clinical laboratory team. Laboratory technicians collect fluid, tissue, and blood samples to perform a variety of tests under the supervision of a pathologist. They may work in hospitals, diagnostic labs, blood banks, and medical clinics, and they work within a team of medical professionals, each performing tasks essential to successful patient care.                                    </p>
                                    <h3>Jop Scope</h3>
                                    <h4>for Diploma MLT</h4>
                                    <ul>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Lab Manager</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Lab Assistan</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Lab Analyst</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Lab Incharge</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Lab Technician</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Lab Technologist</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Lab Supervisor</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Instructor / Tutor</li>
                                    </ul>
                                </div>
                            )
                        }

                        <div className="col-md-4">
                            <img
                                src="https://apisparamedicalinstitute.com/assets/images/new/course/1.jpg"
                                className="img-fluid"
                                alt="Radiology and Medical Imaging Technology"
                            />
                            <div className="row mt-5 bg-white" style={{ height: "200px", margin: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div>
                                    <div>
                                        <Link to='/join'> <button style={{ padding: "10px", background: "#273089", color: "white", border: "none", width: "100%", marginBottom: "20px", fontWeight: "bold" }}>JOIN NOW</button></Link>
                                    </div>
                                    <div>
                                        <Link to='/contact'><button style={{ padding: "10px", color: "#273089", borderColor: "#273089", width: "100%", fontWeight: "bold", background: "white" }} >ENQUIRE NOW</button></Link>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </div>
                <br /><br />
            </div>
            <LastFooter />


        </>
    )
}


export default CourseThree
