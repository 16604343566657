import React from 'react'
import AboutFirst from '../components/about/AboutFirstbox/AboutFirst'
import LoadingSpinner from '../components/LodingSpinner/LodingSpinner'
import AboutApis from '../components/about/AboutApis/AboutApis'
import Vision from '../components/about/OurVision/Vision'
import Director from '../components/about/Director/Director'
import CourseDirector from '../components/about/CourseDirection/CourseDirector'
import Admin from '../components/about/AdminMessage/Admin'
import Principal from '../components/about/Principal/Principal'
import LastFooter from '../components/home/footer/LastFooter'

import { useState,useEffect } from 'react'

function About() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the timeout as needed

    // Cleanup the timer
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <div>
      <AboutFirst/>
      <AboutApis/>
      <Vision/>
      <Director/>
      <CourseDirector/>
      <Admin/>
      <Principal/>
      <LastFooter/>
    </div>
  )
}

export default About
