import React from 'react'

function AboutApis() {
  return (
    <div style={{display:"flex",justifyContent:"center",alignItems:"center",padding:"10px"}}>
         
         <div className="container row" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <div className="col-lg-6">
                    <div style={{color:"#76a631",fontWeight:"bold"}}>ABOUT IRIS</div>
                    <h2>Welcome to IRIS Institute of Paramedical Science</h2>
                    <div style={{textAlign:"justify"}}>
                    IRIS Institute of Paramedical Science is all about experimental learning and building an ever-expanding knowledge base involving knowledge partners and communities. IRIS is where information and ideas flow freely in open and friendly atmosphere a "Knowledge Hub of Health Care Fraternity" in which students learn share and shine.
                    </div>
                </div>
                <div className="col-lg-6">
                      <div>
                        <img src="https://apisparamedicalinstitute.com/assets/images/new/about.png" width={'100%'} height={'auto'} alt="" />
                      </div>
                </div>
         </div>

    </div>
  )
}

export default AboutApis
