import React from 'react'
import Swiper from '../components/home/Swiper/MySwiper'
import Grid from '../components/home/Grid/Grid'
import ThirdComponent from '../components/home/ThirdComponent/ThirdComponent'
import Course from '../components/home/Course/Course'
import LastComponent from '../components/home/lastComponent/LastComponent'
import LastFooter from '../components/home/footer/LastFooter'
import LoadingSpinner from '../components/LodingSpinner/LodingSpinner'
import { useState,useEffect } from 'react'

function Home() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the timeout as needed

    // Cleanup the timer
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <div>
      <Swiper/>
      <Grid/>
      <ThirdComponent/>
      <Course/>
      <LastComponent/>
      <LastFooter/>
    </div>
  )
}

export default Home
