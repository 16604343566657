import React from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './courseOne.css'
import { VscActivateBreakpoints } from "react-icons/vsc";
import LastFooter from '../../home/footer/LastFooter';
import LoadingSpinner from '../../LodingSpinner/LodingSpinner';

function CourseOne() {

    const [click, setClick] = useState('graduate')

    const graduate = () => {
        setClick('graduate')
    }

    const postGraduate = () => {
        setClick('postGraduate')
    }

    const Diploma = () => {
        setClick('Diploma')
    }

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate a loading delay
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000); // Adjust the timeout as needed

        // Cleanup the timer
        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return <LoadingSpinner />;
    }



    return (
        <>
            <div className='first-box'>
                <div className="first-box-title">
                    <div>
                        <h1 className='text-white'>Radiology and Medical Imaging Technology (RMIT)</h1>
                        <div className='d-flex justify-content-center text-white'>
                            <Link to='/' className='me-2' style={{ textDecoration: "none", color: "white" }} > Home </Link>// RMIT
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ background: "#f3f8f9", padding: "20px", paddingTop: "40px" }}>
                <div className="container">
                    <div>
                        <button className='me-1 courseBtn'
                            style={click === 'graduate' ? { background: "#273089", color: "white", fontWeight: "bold", border: "none", padding: "10px", width: "90px" } : { fontWeight: "bold", border: "none", padding: "10px", width: "90px" }}
                            onClick={graduate}
                        >
                            Graduate
                        </button>
                        <button
                            className='me-1 courseBtn'
                            style={click === 'postGraduate' ? { background: "#273089", color: "white", fontWeight: "bold", border: "none", padding: "10px", width: "130px" } : { fontWeight: "bold", border: "none", padding: "10px", width: "130px", }}
                            onClick={postGraduate}
                        >
                            Post Graduate
                        </button>
                        <button
                            className='courseBtn'
                            style={click === 'Diploma' ? { background: "#273089", color: "white", fontWeight: "bold", border: "none", padding: "10px", width: "90px" } : { fontWeight: "bold", border: "none", padding: "10px", width: "90px" }}
                            onClick={Diploma}
                        >
                            Diploma
                        </button>

                    </div>
                    <div className="row mt-1 row-gap-3">
                        {click === 'graduate' && (
                            <div className="col-md-8 bg-white p-4">
                                <h4>B.Voc in Radiology and Medical Imaging Technology (RMIT)</h4>
                                <p style={{ color: "#626262" }}>
                                    B.Voc in Radiology and Medical Imaging Technology technicians are
                                    trained to use advanced radiographic equipment such as CT Scan
                                    (Computed Tomography), MRI (Magnetic Resonance Imaging) along with
                                    basic X-Ray equipment for creating images of a patient's internal
                                    anatomy. These images are used by doctors and specialists to aid in
                                    diagnosis of illnesses and injuries. There is a huge demand for
                                    Radiology Technicians in India and Abroad hence in the B.Voc in
                                    Radiology and Medical Imaging Technology training provided by Apis
                                    Institute of Paramedical Science, they are trained to a proficiency
                                    level wherein they are able to comply with safety regulations involved
                                    in the use of radiography equipment while on the job. After the
                                    completion of Degree, these individuals receive the Degree
                                    certification, which opens huge job prospects for them.
                                </p>
                                <h3>Jop Scope</h3>
                                <h4>for a B.Voc RMIT Graduate</h4>
                                <ul>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> X-ray Technician, Ultrasound Technician</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Radiology Technologists/ Radiographe</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> CT Tech/ CAT Scan Technologist</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> MRI Technician, Radiology Technicians</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Diagnostic Medical Sonographer</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Teacher / Lecture</li>
                                </ul>
                            </div>
                        )}

                        {click === 'postGraduate' && (
                            <div className="col-md-8 bg-white p-4">
                                <h4>Post Graduate in Radiology and Medical Imaging Technology (RMIT)</h4>
                                <p style={{ color: "#626262" }}>
                                    Post Graduate in Radiology and Medical Imaging Technology technicians are trained to use advanced radiographic equipment such as CT Scan (Computed Tomography), MRI (Magnetic Resonance Imaging) along with basic X-Ray equipment for creating images of a patient’s internal anatomy. These images are used by doctors and specialists to aid in diagnosis of illnesses and injuries. There is a huge demand for Radiology Technicians in India and Abroad hence in the B.Voc in Radiology and Medical Imaging Technology training provided by Apis Institute of Paramedical Science, they are trained to a proficiency level wherein they are able to comply with safety regulations involved in the use of radiography equipment while on the job. After the completion of Degree, these individuals receive the Degree certification, which opens huge job prospects for them.
                                </p>
                                <h3>Jop Scope</h3>
                                <h4>for a Post Graduate RMIT</h4>
                                <ul>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> X-ray Technician, Ultrasound Technician</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Radiology Technologists/ Radiographe</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> CT Tech/ CAT Scan Technologist</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> MRI Technician, Radiology Technicians</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Diagnostic Medical Sonographer</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Teacher / Lecture</li>
                                </ul>
                            </div>

                        )}

                        {
                            click === 'Diploma' && (
                                <div className="col-md-8 bg-white p-4">
                                    <h4>Diploma in Radiology and Medical Imaging Technology (RMIT)</h4>
                                    <p style={{ color: "#626262" }}>
                                        Diploma in Radiology and Medical Imaging Technology technicians are trained to use advanced radiographic equipment such as CT Scan (Computed Tomography), MRI (Magnetic Resonance Imaging) along with basic X-Ray equipment for creating images of a patient’s internal anatomy. These images are used by doctors and specialists to aid in diagnosis of illnesses and injuries. There is a huge demand for Radiology Technicians in India and Abroad hence in the B.Voc in Radiology and Medical Imaging Technology training provided by Apis Institute of Paramedical Science, they are trained to a proficiency level wherein they are able to comply with safety regulations involved in the use of radiography equipment while on the job. After the completion of Degree, these individuals receive the Degree certification, which opens huge job prospects for them.
                                    </p>
                                    <h3>Jop Scope</h3>
                                    <h4>for a Diploma RMIT</h4>
                                    <ul>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> X-ray Technician, Ultrasound Technician</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Radiology Technologists/ Radiographe</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> CT Tech/ CAT Scan Technologist</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> MRI Technician, Radiology Technicians</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Diagnostic Medical Sonographer</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' /> Teacher / Lecture</li>
                                    </ul>
                                </div>
                            )
                        }

                        <div className="col-md-4">
                            <img
                                src="https://apisparamedicalinstitute.com/assets/images/new/course/1.jpg"
                                className="img-fluid"
                                alt="Radiology and Medical Imaging Technology"
                            />
                            <div className="row mt-5 bg-white" style={{ height: "200px", margin: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div>
                                    <div>
                                        <Link to='/join'> <button style={{ padding: "10px", background: "#273089", color: "white", border: "none", width: "100%", marginBottom: "20px", fontWeight: "bold" }}>JOIN NOW</button></Link>
                                    </div>
                                    <div>
                                        <Link to='/contact'><button style={{ padding: "10px", color: "#273089", borderColor: "#273089", width: "100%", fontWeight: "bold", background: "white" }} >ENQUIRE NOW</button></Link>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </div>
                <br /><br />
            </div>
            <LastFooter />


        </>
    )
}

export default CourseOne
