import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import './Navbar.css'; // If you have additional CSS for customization
import { Link, useLocation } from 'react-router-dom';

function NavbarComponent() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for tracking dropdown open/close
  const location = useLocation();

  const getNavLinkClass = (path) => {
    return location.pathname === path ? 'nav-link active' : 'nav-link';
  };

  const isDropdownActive = () => {
    return location.pathname.startsWith('/courses') ? 'nav-link active p-0' : 'nav-link p-0';
  };

  const [isMobile, setIsMobile] = useState(false);


  // Toggle sticky navbar based on scroll position
 

  const handleDropdownMouseEnter = () => {
    if (!isMobile) {
      setIsDropdownOpen(true);
    }
  };

  const handleDropdownMouseLeave = () => {
    if (!isMobile) {
      setIsDropdownOpen(false);
    }
  };


  useEffect(() => {
    // Function to check screen size and update state
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    // Initial check
    checkScreenSize();

    // Add event listener for window resize
    window.addEventListener('resize', checkScreenSize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  return (
    <Navbar
      expand="lg"
      sticky='top'
    // Add sticky class when navbar is sticky
    >
      <Container>
        <Navbar.Brand href="/">
          <img
            src={'./images/iris-1.png'}
            height="60"
            className="d-inline-block align-top"
            alt="APIS Institute of Paramedical Science"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="ml-auto" navbar>
            <Link to="/" className={getNavLinkClass('/')} style={{ fontWeight: 'bold' }}>HOME</Link>
            <Link to="/about" className={getNavLinkClass('/about')} style={{ fontWeight: 'bold' }}>ABOUT</Link>
            <NavDropdown
              title="COURSES"
              id="basic-nav-dropdown"
              data-toggle="dropdown"
              className={isDropdownActive()}
              style={{ fontWeight: 'bold' }}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              onMouseEnter={handleDropdownMouseEnter}
              onMouseLeave={handleDropdownMouseLeave}
              show={isDropdownOpen} // Show the dropdown when isDropdownOpen is true
            >
              <NavDropdown.Item as={Link} to="/course1">Radiology and Medical Imaging Technology</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/course2">Operation Theatre Technology</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/course3">Medical Laboratory Technology</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/course4">Optometry Technology</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/course5">Dialysis Technology</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/course6">Cardiac Care Technology</NavDropdown.Item>
            </NavDropdown>
            <Link to="/campus" className={getNavLinkClass('/campus-life')} style={{ fontWeight: 'bold' }}>CAMPUS LIFE</Link>
            <Link to="/contact" className={getNavLinkClass('/contact')} style={{ fontWeight: 'bold' }}>CONTACT US</Link>
            <Link to="/join" className={getNavLinkClass('/join')} style={{ fontWeight: 'bold' }}>JOIN NOW</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;
