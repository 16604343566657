import React from 'react'
import { Link } from 'react-router-dom'

function ContactFirst() {
  return (
    <div className='first-box'>
      <div className="first-box-title">
        <div>
          <h1 className='text-white'>Contact Us</h1>
          <div className='d-flex justify-content-center text-white'>
            <Link to='/' className='me-2' style={{ textDecoration: "none", color: "white" }} > Home </Link>// Contact Us
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactFirst
