import React from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './courseOne.css'
import { VscActivateBreakpoints } from "react-icons/vsc";
import LastFooter from '../../home/footer/LastFooter';
import LoadingSpinner from '../../LodingSpinner/LodingSpinner';


function CourseFive() {

    const [click, setClick] = useState('graduate')

    const graduate = () => {
        setClick('graduate')
    }

    const postGraduate = () => {
        setClick('postGraduate')
    }

    const Diploma = () => {
        setClick('Diploma')
    }

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulate a loading delay
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000); // Adjust the timeout as needed

        // Cleanup the timer
        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return <LoadingSpinner />;
    }



    return (
        <>
            <div className='first-box'>
                <div className="first-box-title">
                    <div>
                        <h1 className='text-white'>Dialysis Technology</h1>
                        <div className='d-flex justify-content-center text-white'>
                            <Link to='/' className='me-2' style={{ textDecoration: "none", color: "white" }} > Home </Link>// Dialysis Technology
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ background: "#f3f8f9", padding: "20px", paddingTop: "40px" }}>
                <div className="container">
                    <div>
                        <button className='me-1 courseBtn'
                            style={click === 'graduate' ? { background: "#273089", color: "white", fontWeight: "bold", border: "none", padding: "10px", width: "90px" } : { fontWeight: "bold", border: "none", padding: "10px", width: "90px" }}
                            onClick={graduate}
                        >
                            Graduate
                        </button>
                        <button
                            className='me-1 courseBtn'
                            style={click === 'postGraduate' ? { background: "#273089", color: "white", fontWeight: "bold", border: "none", padding: "10px", width: "130px" } : { fontWeight: "bold", border: "none", padding: "10px", width: "130px", }}
                            onClick={postGraduate}
                        >
                            Post Graduate
                        </button>
                        <button
                            className='courseBtn'
                            style={click === 'Diploma' ? { background: "#273089", color: "white", fontWeight: "bold", border: "none", padding: "10px", width: "90px" } : { fontWeight: "bold", border: "none", padding: "10px", width: "90px" }}
                            onClick={Diploma}
                        >
                            Diploma
                        </button>

                    </div>
                    <div className="row mt-1 row-gap-3">
                        {click === 'graduate' && (
                            <div className="col-md-8 bg-white p-4">
                                <h4>B.Voc in Dialysis Technology</h4>
                                <p style={{ color: "#626262" }}>
                                    B.Voc Dialysis Technology is a three year degree course in the School of Allied Health Sciences which prepares students to become trained dialysis technicians who provide strong paramedical support system to hospitals for providing specialized care to renal failure patients. Dialysis technicians work closely with Nephrologists and carry out regular dialysis and various preparatory procedures including the sterilization and maintenance of machines for patients of chronic renal failure awaiting renal transplant. They play an important role in ameliorating the sufferings of such patients and help in improving the quality of their life. This is an ever-expanding field and there are a large number of patients in need of such a care in the country and in the world.</p>
                                <h3>Jop Scope</h3>
                                <h4>for B.Voc Dialysis Technology</h4>
                                <ul>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Dialysis Technician</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Dialysis Incharger</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Dialysis Assistant</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Unit Supervisor</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Equipment Operator</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Tutor / Supervisor</li>
                                </ul>
                            </div>
                        )}

                        {click === 'postGraduate' && (
                            <div className="col-md-8 bg-white p-4">
                                <h4>Post Graduate in Dialysis Technology</h4>
                                <p style={{ color: "#626262" }}>
                                    Post Graduate in Dialysis Technology is a three year degree course in the School of Allied Health Sciences which prepares students to become trained dialysis technicians who provide strong paramedical support system to hospitals for providing specialized care to renal failure patients. Dialysis technicians work closely with Nephrologists and carry out regular dialysis and various preparatory procedures including the sterilization and maintenance of machines for patients of chronic renal failure awaiting renal transplant. They play an important role in ameliorating the sufferings of such patients and help in improving the quality of their life. This is an ever-expanding field and there are a large number of patients in need of such a care in the country and in the world.</p>
                                <h3>Jop Scope</h3>
                                <h4>for Post Graduate Dialysis Technology</h4>
                                <ul>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Dialysis Technician</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Dialysis Incharger</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Dialysis Assistant</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Unit Supervisor</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Equipment Operator</li>
                                    <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Tutor / Supervisor</li>
                                </ul>
                            </div>

                        )}

                        {
                            click === 'Diploma' && (
                                <div className="col-md-8 bg-white p-4">
                                    <h4>Diploma in Dialysis Technology</h4>
                                    <p style={{ color: "#626262" }}>
                                        Diploma in Dialysis Technology is a three year degree course in the School of Allied Health Sciences which prepares students to become trained dialysis technicians who provide strong paramedical support system to hospitals for providing specialized care to renal failure patients. Dialysis technicians work closely with Nephrologists and carry out regular dialysis and various preparatory procedures including the sterilization and maintenance of machines for patients of chronic renal failure awaiting renal transplant. They play an important role in ameliorating the sufferings of such patients and help in improving the quality of their life. This is an ever-expanding field and there are a large number of patients in need of such a care in the country and in the world.</p>
                                    <h3>Jop Scope</h3>
                                    <h4>for Diploma Dialysis Technology</h4>
                                    <ul>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Dialysis Technician</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Dialysis Incharger</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Dialysis Assistant</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Unit Supervisor</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Equipment Operator</li>
                                        <li> <VscActivateBreakpoints color='rgb(39, 48, 137)' />Tutor / Supervisor</li>
                                    </ul>
                                </div>
                            )
                        }

                        <div className="col-md-4">
                            <img
                                src="https://apisparamedicalinstitute.com/assets/images/new/course/1.jpg"
                                className="img-fluid"
                                alt="Radiology and Medical Imaging Technology"
                            />
                            <div className="row mt-5 bg-white" style={{ height: "200px", margin: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div>
                                    <div>
                                        <Link to='/join'> <button style={{ padding: "10px", background: "#273089", color: "white", border: "none", width: "100%", marginBottom: "20px", fontWeight: "bold" }}>JOIN NOW</button></Link>
                                    </div>
                                    <div>
                                        <Link to='/contact'><button style={{ padding: "10px", color: "#273089", borderColor: "#273089", width: "100%", fontWeight: "bold", background: "white" }} >ENQUIRE NOW</button></Link>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </div>
                <br /><br />
            </div>
            <LastFooter />


        </>
    )
}

export default CourseFive
