import React from 'react'
import JoinFirst from '../components/JoinNow/JoinFirst'
import JoinForm from '../components/JoinNow/JoinForm'
import LastFooter from '../components/home/footer/LastFooter'
import LoadingSpinner from '../components/LodingSpinner/LodingSpinner'
import { useState,useEffect } from 'react'

function JoinNow() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the timeout as needed

    // Cleanup the timer
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <div>
         <JoinFirst/>
         <JoinForm/>
         <LastFooter/>
    </div>
  )
}

export default JoinNow
