import React from 'react'
import './AboutFirst.css'
import { Link } from 'react-router-dom'

function AboutFirst() {
  return (
    <div className='first-box'>
       <div className="first-box-title">
                <div> 
                  <h1 className='text-white'>About Us</h1>
                  <div className='d-flex justify-content-center text-white'>
                 <Link to='/' className='me-2'style={{textDecoration:"none",color:"white"}} > Home </Link>// About Us
                </div>
                </div>
               
       </div>
    </div>
  )
}

export default AboutFirst
