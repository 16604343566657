import React from 'react'
import './Join.css'
import { Link } from 'react-router-dom';

function JoinForm() {
  return (
    <div style={{padding:"30px"}}>
    <div className="form-container" style={{background:"#f9f8f8"}}>
      <h2>Join Now</h2>
      <form>
        <div className="form-group">
          <input type="text" id="name" name="name" placeholder="Name" required />
        </div>
        <div className="form-group">
          <input type="text" id="mobile" name="mobile" placeholder="Mobile Number" required />
        </div>
        <div className="form-group">
          <input type="text" id="qualification" name="qualification" placeholder="Qualification" required />
        </div>
        <div className="form-group">
          <label htmlFor="course">Interested Course:</label>
          <select id="course" name="course" required>
            <option value="">Select</option>
            <option value="BVoC RMIT">BVoC RMIT</option>
            <option value="BVoC OTT">BVoC OTT</option>
            <option value="BVoC MLT">BVoC MLT</option>
            <option value="BVoC Optometry">BVoC Optometry</option>
            <option value="BVoC Dialysis Technology">BVoC Dialysis Technology</option>
            <option value="BVoC Cardiac Care Technology">BVoC Cardiac Care Technology</option>
            <option value="Post Graduate in RMIT">Post Graduate in RMIT</option>
            <option value="Post Graduate in OTT">Post Graduate in OTT</option>
            <option value="Post Graduate in MLT">Post Graduate in MLT</option>
            <option value="Post Graduate in Optometry">Post Graduate in Optometry</option>
            <option value="Post Graduate in Dialysis Technology">Post Graduate in Dialysis Technology</option>
            <option value="Post Graduate in Cardiac Care Technology">Post Graduate in Cardiac Care Technology</option>
            <option value="Diploma in RMIT">Diploma in RMIT</option>
            <option value="Diploma in OTT">Diploma in OTT</option>
            <option value="Diploma in MLT">Diploma in MLT</option>
            <option value="Diploma in Optometry">Diploma in Optometry</option>
            <option value="Diploma in Dialysis Technology">Diploma in Dialysis Technology</option>
            <option value="Diploma in Cardiac Care Technology">Diploma in Cardiac Care Technology</option>
          </select>
        </div>
        <button type="submit" style={{fontWeight:"bold"}}>SUBMIT</button>
      </form>
      <p className="contact-info">Have any enquiry? <Link to='/contact' >Contact Now</Link></p>
    </div>
    </div>
  );
};
 

export default JoinForm

