import React from 'react'

function Vision() {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
      <div className="container row p-4 pb-5">
        <div className="col-lg-6">
          <div>
            <img src="https://apisparamedicalinstitute.com/assets/images/new/about2.jpg" width={'100%'} height={'450vh'} alt="" />
          </div>
        </div>
        <div className="col-lg-6" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div>
            <h2>Our Vision</h2>
            <p>Our vision is to set up a knowledge hub where students can follow their careers, rather than the other way around. Our institution leverages the best practices to enable academic institutions to deliver new and more engaging models of knowledge management across campuses, thereby creating a new, vibrant, and futuristic learning environment. The results include increased student engagement, improved faculty productivity, innovation powering new models of learning, enhanced online learning, and great career opportunities. IRIS has exceptional faculties and continuously invests in their growth and development as well.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Vision
