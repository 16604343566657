import React from 'react'
import { Link } from 'react-router-dom'
import './CampusFirst.css'

function CampusFirst() {
  return (
    <div className='first-box'>
      <div className="first-box-title">
        <div>
          <h1 className='text-white'>Campus Life at IRIS</h1>
          <div className='d-flex justify-content-center text-white'>
            <Link to='/' className='me-2' style={{ textDecoration: "none", color: "white" }} > Home </Link>// Campus Life
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampusFirst
