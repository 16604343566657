import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavbarComponent from './components/Navbar/NavbarComponent';
import Home from './pages/Home';
import 'bootstrap/dist/css/bootstrap.css';
import About from './pages/About';
import Campus from './pages/Campus';
import Contact from './pages/Contact';
import JoinNow from './pages/JoinNow';
import CourseOne from './components/Course/Course1/CourseOne';
import CourseTwo from './components/Course/Course1/CourseTwo';
import CourseThree from './components/Course/Course1/CourseThree';
import CourseFour from './components/Course/Course1/CourseFour';
import CourseFive from './components/Course/Course1/CourseFive';
import CourseSix from './components/Course/Course1/CourseSix';
import AOS from 'aos';
import 'aos/dist/aos.css'; 


function App() {


// below listed default settings
AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
  

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});


  return (
    <BrowserRouter>
    <NavbarComponent/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/about' element={<About/>}/>
        <Route path='/campus' element={<Campus/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/join' element={<JoinNow/>}/>
        <Route path='/course1' element={<CourseOne/>}/>
        <Route path='/course2' element={<CourseTwo/>}/>
        <Route path='/course3' element={<CourseThree/>}/>
        <Route path='/course4' element={<CourseFour/>}/>
        <Route path='/course5' element={<CourseFive/>}/>
        <Route path='/course6' element={<CourseSix/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
