import React from 'react'
import CampusFirst from '../components/Campus/CampusFirstbox/CampusFirst'
import CampusImage from '../components/Campus/CampusGrid/CampusImage'
import LastFooter from '../components/home/footer/LastFooter'
import LoadingSpinner from '../components/LodingSpinner/LodingSpinner'

import { useState,useEffect } from 'react'

function Campus() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the timeout as needed

    // Cleanup the timer
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <div>
      <CampusFirst/>
      <CampusImage/>
      <LastFooter/>
    </div>
  )
}

export default Campus
