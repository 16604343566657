import React from 'react';
import { Card, CardBody, CardTitle, CardText, } from 'reactstrap';
import { TfiEmail } from "react-icons/tfi";
import { Link } from 'react-router-dom';

function Admin() {
  return (
    <div style={{background:"#f3f8f9",marginTop:"20px",}}>
            <div className="container row pb-3">
                <div className="col-lg-6" style={{display:"flex",justifyContent:"center", paddingTop:"20px"}}>
                    <Card className='shadow-lg' style={{ width: '23rem', padding: "30px", border: "none",height:"fit-content" }}>
                        <img
                            alt="Sample"
                            src="https://apisparamedicalinstitute.com/assets/images/new/t3.jpg"
                            style={{ borderRadius: "10px" }}
                            height={'330vh'}
                        />
                        <CardBody>
                            <CardTitle tag="h5" style={{ textAlign: "center" }}>
                            Muhammed Aslam BS
                            </CardTitle>
                            <CardText style={{ textAlign: "center" }}>
                                <div>
                                    <span>
                                        <TfiEmail />
                                    </span>
                                    <Link style={{ textDecoration: "none", color: "black" }}> admin@apisparamedicalinstitute.com</Link>
                                </div>
                            </CardText>
                        </CardBody>
                    </Card>
                </div>
                <div className="col-lg-6">
                    <h2 className='mt-4'>Admin’s Message</h2>
                    <br />
                    <p>
                    Welcome to IRIS Paramedical Institute prospectus! We offer excellent education and training in healthcare with a focus on paramedical sciences. Our experienced faculty provides a supportive learning environment that promotes academic and professional growth, preparing students for successful careers in the healthcare industry. Explore our prospectus to learn more about our programs and opportunities. Contact us with any questions
                    </p>
                </div>
            </div>
            </div>
    
  )
}

export default Admin
