import React from 'react'
import Address from '../components/contact/Address/Address'
import ContactFirst from '../components/contact/ContactFirst/ContactFirst'
import LastFooter from '../components/home/footer/LastFooter'
import LoadingSpinner from '../components/LodingSpinner/LodingSpinner'
import { useState,useEffect } from 'react'

function Contact() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      // Simulate a loading delay
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000); // Adjust the timeout as needed
  
      // Cleanup the timer
      return () => clearTimeout(timer);
    }, []);
  
    if (loading) {
      return <LoadingSpinner />;
    }
    return (
        <div>
            <ContactFirst />
            <Address />
            <LastFooter/>
        </div>
    )
}

export default Contact
